.szh-menu-container ul {
  box-shadow: 1px 5px 21px 7px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 1px 5px 21px 7px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 1px 5px 21px 7px rgba(0, 0, 0, 0.57);
}

.principal-nav-bar {
  margin-right: 14% !important;
}

@media only screen and (min-width: 1024px) {
  .principal-nav-bar {
    margin-right: 25% !important;
  }

  button.szh-menu-button {
    right: 200px !important;
  }

}

@media only screen and (min-width: 1200px) {
  .principal-nav-bar {
    margin-right: 30% !important;
  }

  button.szh-menu-button {
    right: 300px !important;
  }

}

button.szh-menu-button {
  border: none;
  background-color: transparent;
  padding: 0 !important;
  margin: 0 0 0 10px !important;
  float: right;
  position: absolute;
  right: 80px;
  top: 12px;
}

.language-radio-group-container {
  padding: 8px 8px 0px 8px;
  width: 110px;
}

.language-radio-group-container>div {
  display: inline-flex;
  margin-bottom: 8;
}

.language-radio-group-container>div input {
  margin-right: 4;
}